import { registerApplication, start } from "single-spa";

registerApplication({
  name: "@carrefour/micro-header",
  app: () => System.import("@carrefour/micro-header"),
  activeWhen: (location) =>
    location.pathname.startsWith("/") && location.pathname.length !== 1,
});

registerApplication({
  name: "@carrefour/micro-login",
  app: () => System.import("@carrefour/micro-login"),
  activeWhen: (location) =>
    location.pathname.startsWith("/") && location.pathname.length === 1,
});

registerApplication({
  name: "@carrefour/micro-atendimento",
  app: () => System.import("@carrefour/micro-atendimento"),
  activeWhen: (location) => location.pathname.startsWith("/atendimento"),
});

start();
